import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth-context";
import { UserLeagueDropdown } from "../user-leagues/user-league-dropdown";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import { getUserPlayers } from "../../api/roster";
import { useLeagueSelector } from "../../context/league-selector-context";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import LoadingIndicator from "../loading-indicator";
import { Col, Row } from "reactstrap";
import { HitterInfoContainer } from "../hitter-finder/HitterInfoContainer";
import { get_league_settings } from "../../api/yahoo-integration-info";



const RosterResearcher = (props) => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const [hitters, setHitters] = useState([]);
    const [userLeagues, setUserLeagues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [yahooLeagueSettings, setYahooLeagueSettings] = useState();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();

    useEffect(() => {
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);

        if (selectedLeague) {

            const getRosterAsync = async (userToken, refreshUserToken, leagueId, teamId) => {
                setIsLoading(true);
                const result = await getUserPlayers(userToken, refreshUserToken, leagueId, teamId);
                if (result.success) {
                    setHitters(result.data.hitters);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.error(result.error)
                }
                setIsLoading(false);
            }

            getRosterAsync(userToken, refreshUserToken, selectedLeague.league_id, selectedLeague.team_id);

            const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_settings(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setYahooLeagueSettings(result.data);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }
           
            getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id)
        }

    }, [selectedLeague])

    const renderLoadingIndicator = () => {
        return <LoadingIndicator></LoadingIndicator>
    }



    return (
    <div className='App fit-better'>
        <div className="roster-researcher-container">
            <div className="roster-researcher-body">     
                <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
                {isLoading && renderLoadingIndicator()}
            
            <Row>
                <Col>{selectedLeague && isLoading ? renderLoadingIndicator() : <HitterInfoContainer hitters={hitters} selectedLeague={selectedLeague} yahooLeagueSettings={yahooLeagueSettings} setYahooLeagueSettings={setYahooLeagueSettings}></HitterInfoContainer>}</Col>
                </Row>
            </div>
        </div>

    </div>)
}

export default RosterResearcher;