import { useEffect, useState } from "react";
import { getUserRoster } from "../../api/roster";
import { handleUnauthorized } from "../errors/handle-unauthorized";
import { useAuthContext } from "../../context/auth-context";
import { UserLeagueDropdown } from "../user-leagues/user-league-dropdown";
import { useLeagueSelector } from "../../context/league-selector-context";
import { get_user_leagues_async } from "../user-leagues/get-user-leagues";
import LoadingIndicator from "../loading-indicator";
import { Col, Row } from "reactstrap";
import { getLeagueStats, getWeekByWeek } from "../../api/league-matchups";
import { WeeklyStatRankingTrendChart } from "./WeeklyStatRankingTrendChart";
import { RosteredPlayersTable } from "./RosteredPlayersTable";
import { get_league_settings } from "../../api/yahoo-integration-info";
import { SeasonRanksByCategory } from "./SeasonRanksByCategory";
import { PlayerStatDisplayCol } from "../hitter-finder/PlayerStatDisplayCol";
import { calculate_weekly_scoreboard_details, get_subset_scoreboard_details } from "./weekly_scoreboard_details";
import { ResponsiveContainer } from "recharts";
import { convertToDecimalIP } from "../../utility/baseball-math";
import { SeasonRanksSubsetButtonGroup } from "./SeasonRanksSubsetButtonGroup";
import { TeamStatRadarChart } from "./TeamStatRadarChart";
import { LeagueStatPieChart } from "./LeagueStatPieChart";
import { getHitterStatsForTimeframes, getPitcherStatsForTimeframes } from "../../api/hitter-stats";

const StrategyBuilder = (props) => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const [hitters, setHitters] = useState([]);
    const [pitchers, setPitchers] = useState([]);
    const [userLeagues, setUserLeagues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [leagueStats, setLeagueStats] = useState();
    const [selectedStat, setSelectedStat] = useState();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();
    const [yahooLeagueSettings, setYahooLeagueSettings] = useState();
    const [weekByWeekStats, setWeekByWeekStats] = useState();
    const [weeklyScoreboard, setWeeklyScoreboard] = useState();
    const [subsetView, setSubsetView] = useState('season');
    const [hitterSubsetStats, setHitterSubsetStats] = useState([]);
    const [pitcherSubsetStats, setPitcherSubsetStats] = useState([]);

    useEffect(() => {
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken);

        if (selectedLeague) {

            const getRosterAsync = async (userToken, refreshUserToken, leagueId, teamId) => {
                const result = await getUserRoster(userToken, refreshUserToken, leagueId, teamId);
                if (result.success) {
                    setHitters(result.data.filter(p => !p.eligible_positions.position.includes('P')));
                    setPitchers(result.data.filter(p => p.eligible_positions.position.includes('P')));
                } else {
                    handleUnauthorized(result.error, logout);
                    console.error(result.error)
                }
            }

            getRosterAsync(userToken, refreshUserToken, selectedLeague.league_id, selectedLeague.team_id);

            const getLeagueStatsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await getLeagueStats(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setLeagueStats(result.data);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.error(result.error)
                }
            }

            getLeagueStatsAsync(userToken, refreshUserToken, selectedLeague.league_id)

            const getWeekByWeekAsync = async (userToken, refreshUserToken, leagueId) => {
                setIsLoading(true)
                const result = await getWeekByWeek(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setWeekByWeekStats(result.data.weekly_stats)
                    setWeeklyScoreboard(result.data.weekly_scoreboard);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.error(result.error)
                }
                setIsLoading(false)
            }

            getWeekByWeekAsync(userToken, refreshUserToken, selectedLeague.league_id)

            const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_settings(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setYahooLeagueSettings(result.data);
                    const defaultStat = result.data.settings.stat_categories.stats.stat[1];
                    setSelectedStat(defaultStat);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }

            getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id)

        }


    }, [selectedLeague])

    useEffect(() => {
        if (weeklyScoreboard?.length > 0 && hitters?.length > 0) {
            const getHitterStatsForSpecifiedTimeframesAsync = async (userToken, refreshUserToken, timeframes, hitters) => {
                const requestBody = { timeframes, hitter_yahoo_ids: hitters };
                const resp = await getHitterStatsForTimeframes(userToken, refreshUserToken, requestBody);
                if (resp.success) {
                    setHitterSubsetStats(resp.data);
                } else {
                    handleUnauthorized(resp.error, logout);
                    console.error(resp.error);
                }
            }

            const hitter_yahoo_ids = hitters.map(h => h.player_id);

            const timeframes = weeklyScoreboard.map(week => {
                const weekNum = week.week;
                const any_matchup = week.scoreboard[0];
                return { week: weekNum, week_start: any_matchup.week_start, week_end: any_matchup.week_end };
            })

            getHitterStatsForSpecifiedTimeframesAsync(userToken, refreshUserToken, timeframes, hitter_yahoo_ids);
        }
        if (weeklyScoreboard?.length > 0 && pitchers?.length > 0) {
            const getPitcherStatsForSpecifiedTimeframesAsync = async (userToken, refreshUserToken, timeframes, pitchers) => {
                const requestBody = { timeframes, pitcher_yahoo_ids: pitchers };
                const resp = await getPitcherStatsForTimeframes(userToken, refreshUserToken, requestBody);
                if (resp.success) {
                    setPitcherSubsetStats(resp.data);
                } else {
                    handleUnauthorized(resp.error, logout);
                    console.error(resp.error);
                }
            }

            const pitcher_yahoo_ids = pitchers.map(h => h.player_id);

            const timeframes = weeklyScoreboard.map(week => {
                const weekNum = week.week;
                const any_matchup = week.scoreboard[0];
                return { week: weekNum, week_start: any_matchup.week_start, week_end: any_matchup.week_end };
            })

            getPitcherStatsForSpecifiedTimeframesAsync(userToken, refreshUserToken, timeframes, pitcher_yahoo_ids);
        }
    }, [weeklyScoreboard, hitters]);

    const handle_league_selection = (league) => {
        resetState();
        setSelectedLeague(league)
    }

    const resetState = () => {
        setHitters([]);
        setPitchers([]);
        setLeagueStats([]);
        setSelectedStat(null);
        setYahooLeagueSettings(null);
        setWeekByWeekStats(null);
        setWeeklyScoreboard(null);
        setSubsetView('season');
        setHitterSubsetStats([])
        setPitcherSubsetStats([])
    }

    const renderLoadingIndicator = () => {
        return <LoadingIndicator></LoadingIndicator>
    }

    // console.log(hitters)
    // console.log(weeklyScoreboard)
    // console.log(hitterSubsetStats)

    const league_info = { scoring_type: yahooLeagueSettings?.scoring_type, league_id: yahooLeagueSettings?.league_id }
    if (selectedLeague && yahooLeagueSettings?.scoring_type !== 'head') {
        return (
            <div className="text-align-center fit-better">
                <p>
                    Strategy Builder only works for Head to Head scoring leagues (for now).
                </p>
                <p>
                    Your league info:
                </p>
                <p>Scoring Type: {league_info.scoring_type}</p>

                <p>
                    <a className="discord-link" href='https://discord.gg/aAmmCAwyJw' target='_'>Join our Discord server</a> and let us know that you'd like support for your league's scoring type.
                </p>
            </div>
        )
    }


    const roster = { hitters, pitchers }

    const my_teams_weekly_stats = weekByWeekStats?.find(x => x.team.team_id === selectedLeague.team_id);


    const my_teams_matchups = weeklyScoreboard?.map(week => {
        const weekNum = week.week;
        const my_matchup = week.scoreboard.find(s => s.teams.some(t => t.team_id == selectedLeague.team_id));
        return { week: weekNum, matchup_result: my_matchup }
    })




    const stat_id = selectedStat?.stat_id || 7;

    const stat_record = { wins: 0, losses: 0, ties: 0 };

    const stat_specific_results = my_teams_matchups?.map(m => {
        const week = m.week;
        const stat_we_care_about = m.matchup_result.stat_winners.find(sw => sw.stat_id == stat_id);
        const wasTied = stat_we_care_about.is_tied == 1;
        let result = '';
        if (m.matchup_result.status === 'midevent') {
            result = null;
        } else if (wasTied) {
            result = 'tie'
            stat_record.ties += 1;
        } else {
            const winner_key_parts = stat_we_care_about.winner_team_key.split('.');
            result = winner_key_parts.slice(-1) == selectedLeague.team_id ? 'win' : 'loss';
        }
        return { week, result };
    })



    const scoreboard_details = calculate_weekly_scoreboard_details(weeklyScoreboard, weekByWeekStats)
    const test = get_subset_scoreboard_details(scoreboard_details, subsetView)
    const my_teams_record_subset = test.find(t => t.team.team_id == selectedLeague.team_id)


    const num_weeks_into_season = weekByWeekStats?.[0]?.team_stats_by_week?.length;

    return (
        <div className="App fit-better strategy-builder">
            <div className="strategy-builder-body">
                <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={handle_league_selection}></UserLeagueDropdown>
                {selectedLeague && <Row className="justify-center text-align-center season-rank-subset-btn-group sticky-top">
                    <Col>
                        <SeasonRanksSubsetButtonGroup num_weeks_into_season={num_weeks_into_season} subsetView={subsetView} setSubsetView={setSubsetView}></SeasonRanksSubsetButtonGroup>
                    </Col>
                </Row>}
                {selectedLeague && <Row className="flex-nowrap horizontal-scroll ">
                    <Col xs='0' sm='0' md='2' className='flex-shrink-1'></Col>
                    <Col sm='4'>
                        <TeamStatRadarChart stat_group='batting' subset_selected_key={subsetView} team_id={selectedLeague.team_id} week_by_week_stats={weekByWeekStats}></TeamStatRadarChart>
                    </Col>
                    <Col>
                        <TeamStatRadarChart stat_group='pitching' subset_selected_key={subsetView} team_id={selectedLeague.team_id} week_by_week_stats={weekByWeekStats}></TeamStatRadarChart>
                    </Col>
                </Row>}

                <Row className="text-align-center">
                    {isLoading && renderLoadingIndicator()}
                </Row>
                {my_teams_weekly_stats && my_teams_matchups && <> <Row className="text-align-center season-ranks  margin-5">
                    <Col>
                        <SeasonRanksByCategory selectedStat={selectedStat} team_record={my_teams_record_subset} subsetView={subsetView} setSubsetView={setSubsetView} weeklyScores={weeklyScoreboard} weekByWeekStats={my_teams_weekly_stats} stat_categories={yahooLeagueSettings?.settings.stat_categories} setSelectedStat={setSelectedStat}></SeasonRanksByCategory>
                    </Col>
                </Row>
                    {selectedStat &&
                        <WeeklyStatBreakdown selectedLeague={selectedLeague} week_by_week_stats={weekByWeekStats} subset_selected_key={subsetView} team_matchup={my_teams_matchups} stat_specific_results={stat_specific_results} team_weekly_ranks={my_teams_weekly_stats.weekly_stat_rankings} selectedStat={selectedStat} league_weekly_ranks={weekByWeekStats} team_id={selectedLeague.team_id}></WeeklyStatBreakdown>
                    }

                    <Row className="bottom-border">
                        <RosteredPlayersTable subset_selected_key={subsetView} roster={roster} hitter_stats_by_timeframe={hitterSubsetStats} pitcher_stats_by_timeframe={pitcherSubsetStats} selectedStat={selectedStat} setSelectedStat={setSelectedStat} stat_categories={yahooLeagueSettings?.settings.stat_categories}></RosteredPlayersTable>
                    </Row></>}

                <Row className="spacer-25"></Row>
                <Row>
                    {/* <Col>{selectedLeague && isLoading ? renderLoadingIndicator() : <HitterInfoContainer hitters={hitters} selectedLeague={selectedLeague} yahooLeagueSettings={leagueSettings} setYahooLeagueSettings={setLeagueSettings}></HitterInfoContainer>}</Col> */}
                </Row>
            </div>
        </div>
    )
}

export default StrategyBuilder;


const WeeklyStatBreakdown = (props) => {

    const { selectedLeague, selectedStat, team_matchup, team_id, team_weekly_ranks, league_weekly_ranks, subset_selected_key, week_by_week_stats } = props;

    const selected_stat_or_default = selectedStat || { stat_id: 7 };

    const teamCount = league_weekly_ranks.length;

    const top_x_finishes = {};
    for (let i = 1; i <= teamCount; i++) {
        top_x_finishes[i] = 0;
    }

    const weeks_excluded = subset_selected_key == 'season' ? 0 : team_matchup.length - subset_selected_key.slice(1);
    const excluded_week_numbers = [];
    for (let i = 0; i < weeks_excluded; i++) {
        excluded_week_numbers.push(i);
    }

    const stat_specific_results = team_matchup?.map((m, index) => {
        const week = m.week;
        const matchup_result_for_stat_we_care_about = m.matchup_result.stat_winners.find(sw => sw.stat_id == selected_stat_or_default.stat_id);
        const wasTied = matchup_result_for_stat_we_care_about.is_tied == 1;
        let result = '';
        if (m.matchup_result.status === 'midevent') {
            result = null;
        } else if (wasTied) {
            result = 'tie'

        } else {
            const winner_key_parts = matchup_result_for_stat_we_care_about.winner_team_key.split('.');

            if (winner_key_parts.slice(-1) == team_id) {

                result = 'win';
            } else {

                result = 'loss';
            }
        }

        // ====== vs league each week ======
        const get_stat_we_care_about = (team, weekNumber, stat_id) => {
            const team_stats = team.team_stats_by_week;
            const this_weeks_stats = team_stats.find(w => w.week == weekNumber);
            const stat_of_interest = this_weeks_stats.weekly_stats.find(s => s.stat_id == stat_id);
            return stat_of_interest;
        }

        const get_stat_rank_for_stat_we_care_about = (team, weekNumber, stat_id) => {
            const stat_of_interest = get_stat_we_care_about(team, weekNumber, stat_id);
            return parseFloat(stat_of_interest.rank);
        }

        if (!excluded_week_numbers.includes(m.week)) {
            const my_team = league_weekly_ranks.find(t => t.team.team_id == team_id);
            const rank = get_stat_rank_for_stat_we_care_about(my_team, m.week, selected_stat_or_default.stat_id);
            top_x_finishes[rank] += 1;
        }



        return { week, result };
    })



    return (
        <div className='weekly-stat-breakdown'>
            <Row>
                <strong className="text-align-center">{`${selectedStat?.name}`}</strong>
            </Row>
            
            <Row className="flex-nowrap horizontal-scroll pie-chart-weekly-stat-container">
                <Col className="light-gray-border justify-items-center">
                    <LeagueStatPieChart no_border={true} selectedStat={selectedStat} subset_selected_key={subset_selected_key} team_id={selectedLeague.team_id} week_by_week_stats={week_by_week_stats}></LeagueStatPieChart>
                </Col>
                <Col  className="light-gray-border">
                    <Row>
                        <Col></Col>
                        <PlayerStatDisplayCol label='1st' size='sm' value={top_x_finishes[1]}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol label='Top 3' size='sm' value={top_x_finishes[3] + top_x_finishes[2] + top_x_finishes[1]}></PlayerStatDisplayCol>
                        <PlayerStatDisplayCol label='Bottom 3' size='sm' value={top_x_finishes[teamCount] + top_x_finishes[teamCount - 1] + top_x_finishes[teamCount - 2]}></PlayerStatDisplayCol>
                        <Col></Col>
                    </Row>
                    <Row>
                        <WeeklyStatRankingTrendChart no_border={true} subset_selected_key={subset_selected_key} stat_specific_results={stat_specific_results} team_weekly_ranks={team_weekly_ranks} selectedStat={selectedStat} league_weekly_ranks={league_weekly_ranks} team_id={team_id}></WeeklyStatRankingTrendChart>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}




export function get_max_stat_value(league_stats, stat_id) {
    let values = [];
    let sortOrder = '0';  // Default to ascending

    league_stats.forEach(teamStats => {
        teamStats.stats.forEach(stat => {

            if (stat.stat_id == stat_id) {
                values.push(parseFloat(stat?.value || 0));
                sortOrder = stat.sort_order;  // Assuming all same stat_id have the same sort_order
            }
        });
    });

    return Math.max(...values);
}

export function get_min_stat_value(league_stats, stat_id) {
    let values = [];
    let sortOrder = '0';  // Default to ascending

    league_stats.forEach(teamStats => {
        teamStats.stats.forEach(stat => {
            if (stat.stat_id == stat_id) {
                values.push(parseFloat(stat.value));
                sortOrder = stat.sort_order;  // Assuming all same stat_id have the same sort_order
            }
        });
    });


    return Math.min(...values);
}


export function get_average_stat_value(league_stats, stat_id) {
    let values = [];

    let is_avg = false;
    let AB = 0, H = 0;

    let is_era = false, is_whip = false;
    let IP = 0, WH = 0, ER = 0;

    league_stats.forEach(teamStats => {
        const IPs = parseFloat(convertToDecimalIP(teamStats.stats.find(x => x.abbr == 'IP')?.value));
        IP += IPs;

        teamStats.stats.forEach((stat, index) => {


            if (stat.stat_id == stat_id) {
                if (stat.abbr == 'AVG') {
                    is_avg = true;
                    const HperABs = teamStats.stats.find(x => x.abbr == 'H/AB')?.value;
                    if (HperABs) {
                        const parts = HperABs.split('/');
                        // console.log(parts)
                        if (parts.length > 1) {
                            AB += parseFloat(parts[1]);
                            H += parseFloat(parts[0]);
                        }
                    }

                } else if (stat.abbr === 'ERA') {
                    is_era = true;
                    const ERs = IPs * parseFloat(stat.value) / 9;
                    ER += ERs;

                } else if (stat.abbr === 'WHIP') {
                    is_whip = true;
                    const WHs = IPs * parseFloat(stat.value) / 9;
                    WH += WHs;

                } else {
                    values.push(parseFloat(stat?.value || 0));
                }

            }
        });
    });

    if (is_avg) {

        return AB == 0 ? 0 : H / AB;
    }
    if (is_era) {

        return 9 * ER / IP;
    }
    if (is_whip) {

        return 9 * WH / IP;
    }

    if (values.length === 0) {
        return null;  // Return null if no values found for the given stat_id
    }



    let sum = values.reduce((acc, val) => acc + val, 0);
    // debugger;
    return sum / values.length;
}


export function populate_stat_sets(subset_selected_key, teams_stats, team_specific_set_of_stats_for_subset, rest_of_league_set_of_stats_for_subset, week_by_week_stats, whole_league_set_of_stats_for_subset) {
    const filtered_week_by_week_stats = week_by_week_stats.filter(x => x.team.team_id !== teams_stats.team.team_id)
    if (subset_selected_key.startsWith('W')) {
        const my_teams_stats_by_week = teams_stats.team_stats_by_week;
        const week_number = subset_selected_key.slice(1);
        team_specific_set_of_stats_for_subset = my_teams_stats_by_week.find(x => x.week == week_number).weekly_stats;

        rest_of_league_set_of_stats_for_subset = filtered_week_by_week_stats.map(wbw => {
            const mapped = { team: wbw.team, stats: wbw.team_stats_by_week.find(x => x.week == week_number).weekly_stats };
            return mapped;
        });

        whole_league_set_of_stats_for_subset = week_by_week_stats.map(wbw => {
            const mapped = { team: wbw.team, stats: wbw.team_stats_by_week.find(x => x.week == week_number).weekly_stats };
            return mapped;
        });

    } else {
        const my_teams_season_substotals = teams_stats.season_subtotals;

        if (subset_selected_key === 'season') {
            team_specific_set_of_stats_for_subset = my_teams_season_substotals.slice(-1)[0].stats;

            rest_of_league_set_of_stats_for_subset = filtered_week_by_week_stats.map(wbw => {
                const mapped = { team: wbw.team, stats: wbw.season_subtotals.slice(-1)[0].stats };
                return mapped;
            });

            whole_league_set_of_stats_for_subset = week_by_week_stats.map(wbw => {
                const mapped = { team: wbw.team, stats: wbw.season_subtotals.slice(-1)[0].stats };
                return mapped;
            });
        } else {
            team_specific_set_of_stats_for_subset = my_teams_season_substotals.find(x => x.subset == subset_selected_key).stats;

            rest_of_league_set_of_stats_for_subset = filtered_week_by_week_stats.map(wbw => {
                const mapped = { team: wbw.team, stats: wbw.season_subtotals.find(x => x.subset == subset_selected_key).stats };
                return mapped;
            });

            whole_league_set_of_stats_for_subset = week_by_week_stats.map(wbw => {
                const mapped = { team: wbw.team, stats: wbw.season_subtotals.find(x => x.subset == subset_selected_key).stats };
                return mapped;
            });
        }
    }
    return { team_specific_set_of_stats_for_subset, rest_of_league_set_of_stats_for_subset, whole_league_set_of_stats_for_subset };
}
