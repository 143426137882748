import { Button, ButtonGroup } from "reactstrap";

export const SeasonRanksSubsetButtonGroup = (props) => {
    const { setSubsetView, subsetView, num_weeks_into_season } = props;
    const counter = num_weeks_into_season;
    if (counter <= 1) {
        return <></>
    }

    const buttons = [];

    buttons.push(<Button outline active={subsetView === 'L0'} onClick={() => setSubsetView('L0')}>This Week</Button>);

    const intervals = [1,2,3,5];

    for (let i = 1; i < counter; i++) {
        if (intervals.includes(i)) {
            buttons.push(<Button outline active={subsetView === `L${i}`} onClick={() => setSubsetView(`L${i}`)}>L{i}</Button>);
        }
    }

    buttons.push(<Button outline active={subsetView === 'season'} onClick={() => setSubsetView('season')}>Season</Button>);

    return <ButtonGroup className='season-rank-subset-btn-group' size='sm'>{buttons}</ButtonGroup>

    return (
        <ButtonGroup className='season-rank-subset-btn-group' size='sm'>
            <Button outline active={subsetView === 'L0'} onClick={() => setSubsetView('L0')}>This Week</Button>
            <Button outline active={subsetView === 'L1'} onClick={() => setSubsetView('L1')}>L1</Button>
            <Button outline active={subsetView === 'L2'} onClick={() => setSubsetView('L2')}>L2</Button>
            <Button outline active={subsetView === 'L3'} onClick={() => setSubsetView('L3')}>L3</Button>
            <Button outline active={subsetView === 'L5'} onClick={() => setSubsetView('L5')}>L5</Button>
            <Button outline active={subsetView === 'season'} onClick={() => setSubsetView('season')}>Season</Button>
        </ButtonGroup>
    );

};
