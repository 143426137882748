import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";


export function buildRecentStats(stats, stats_to_show, breakdown) {
    const headerCells = stats_to_show.map(x => <th>{x}</th>);


    const getCells = (stats, temp) => {
        const result = [];
        stats_to_show.forEach(s => {
            const value = stats?.[s];
            if (s == 'G') {
                result.push(<th>L{value || temp}</th>)
                return;
            }
            if (!value) {
                result.push(<td className='text-size-80'>--</td>)
                return;
            }
            if (value == 0) {
                result.push(<td className='text-size-80'>0</td>)
                return;
            }
            
            if (s === 'AVG') {
                result.push(<td className='text-size-80'>{remove_leading_zeros(value.toFixed(3))}</td>)
                return;
            }

            if (breakdown == 'per_game') {
                let formatted = remove_leading_zeros(value.toFixed(2));
                if (formatted.endsWith('.00')) {
                    // Remove the ".00" part
                    formatted = formatted.slice(0, -3);
                }

                result.push(<td className='text-size-80'>{formatted}</td>)
            } else {
                result.push(<td className='text-size-80'>{remove_leading_zeros(value)}</td>)
            }


        })
        return result;
    }
    let lastGameCells;
    if (stats.L1) {
        lastGameCells = getCells(stats.L1?.[breakdown], '1');
    }
    const last3gamesCells =  getCells(stats.L3?.[breakdown], '3');

    const last7gamesCells = getCells(stats.L7?.[breakdown], '7');

    const last10gamesCells = getCells(stats.L10?.[breakdown], '10');

    const last14gamesCells = getCells(stats.L14?.[breakdown], '14');

    const table_props = { headerCells, lastGameCells, last3gamesCells, last7gamesCells, last10gamesCells, last14gamesCells };
    return table_props;
}