import { useState } from "react";
import { buildRecentStats } from "../recent-stats/recent_stats_table_builder";
import { RecentGamesTable } from "../recent-stats/RecentGamesTable";
import { Col } from "reactstrap";
import { getEnding } from "../../display-helpers/number-endings";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";

export const RecentTeamHittingStatsView = (props) => {
    const [activeView, setActiveView] = useState('rankings');
    const { logo, statRankings, teamHittingStats, leagueWideStats } = props;

    const stat_rankings_table_props = buildTeamStatsRankings(statRankings);

    const stats_to_show = ["G", "R", "K", "H", "AVG"];
    const per_game_table_props = buildRecentStats(teamHittingStats.recent_stats, stats_to_show, 'per_game');

    const per_game_vs_league_avg_table_props = buildRecentStatsVsLeagueAverage(teamHittingStats.recent_stats, stats_to_show, 'per_game', leagueWideStats.hitter);


    const toggleView = () => {
        if (activeView === 'vs-league-avg') {
            setActiveView('rankings');
        } else if (activeView === 'rankings') {
            setActiveView('per-game');
        } else {
            setActiveView('vs-league-avg');
        }
    };

    const statRankingsTitle = <span>{logo}'s Rankings</span>;
    const perGameTitle = <span>{logo}'s Avg Per Game</span>;
    const vsAvgTitle = <span>{logo} vs League Avg</span>;

    return (

        <Col onClick={toggleView}>
            {activeView == 'rankings' && <RecentGamesTable title={statRankingsTitle} {...stat_rankings_table_props}></RecentGamesTable>}
            {activeView == 'per-game' && <RecentGamesTable title={perGameTitle} {...per_game_table_props}></RecentGamesTable>}
            {activeView == 'vs-league-avg' && <RecentGamesTable title={vsAvgTitle} {...per_game_vs_league_avg_table_props}></RecentGamesTable>}
        </Col>

    );
};


const buildTeamStatsRankings = (statRankings) => {

    const ranked_stats = ["R", "K", "H", "AVG"];
    const periods = ['l1_rank', 'l3_rank', 'l7_rank', 'l10_rank', 'l14_rank'];

    const headerCells = ranked_stats.map(x => <th>{x}</th>);
    headerCells.unshift(<th>G</th>)

    const calc_color_for_k = (rank) =>
        rank > 20 ?
            'red-gradient' :
            rank < 10 ? 'green-gradient' : '';

    const calc_color_for_r = (rank) =>
        rank > 20 ?
            'green-gradient' :
            rank < 10 ? 'red-gradient' : '';

    const color_rules_dictionary = {
        K: calc_color_for_k,
        R: calc_color_for_r,
        H: calc_color_for_r,
        AVG: calc_color_for_r
    };

    const cells = periods.map(period => {
        const mapped = [];
        mapped.push(<th>{period.split('_')[0].toUpperCase()}</th>)
        ranked_stats.forEach(statName => {
            const statRanking = statRankings.find(x => x.stat == statName)?.[period];

            const color = color_rules_dictionary[statName](statRanking);

            if (!statRanking) {
                mapped.push(<td className={`text-size-80 `}>--</td>)
                return;
            }
            mapped.push(<td className={`text-size-80 ${color}`}>{statRanking + getEnding(Number(statRanking))}</td>)
        })
        return mapped;
    })

    const lastGameCells = cells[0];
    const last3gamesCells = cells[1]
    const last7gamesCells = cells[2]
    const last10gamesCells = cells[3]
    const last14gamesCells = cells[4]

    const stat_rankings_table_props = { headerCells, lastGameCells, last3gamesCells, last7gamesCells, last10gamesCells, last14gamesCells }

    return stat_rankings_table_props;


}


const buildRecentStatsVsLeagueAverage = (oppTeamStats, statsToShow, breakdown, leagueStats) => {

    const headerCells = statsToShow.map(x => <th>{x}</th>);

    const lg_total_stats = leagueStats.all.totals[0];
    const total_games = lg_total_stats.TG;

    const lg_runs_per_game = lg_total_stats.R / total_games;
    const lg_ks_per_game = lg_total_stats.SO / total_games;
    const lg_hs_per_game = lg_total_stats.H / total_games;
    const lg_avg_per_game = lg_total_stats.AVG; // i think so right

    const lg_avgs = { R: lg_runs_per_game, K: lg_ks_per_game, H: lg_hs_per_game, AVG: lg_avg_per_game };


    const buffer = .06;
    const calc_color_for_k = (team_avg_quotient) =>
        team_avg_quotient - buffer > 1 ?
            'green-gradient' :
            team_avg_quotient + buffer < 1 ? 'red-gradient' : '';

    const calc_color_for_r = (team_avg_quotient) =>
        team_avg_quotient - buffer > 1 ?
            'red-gradient' :
            team_avg_quotient + buffer < 1 ? 'green-gradient' : '';

    const color_rules_dictionary = {
        K: calc_color_for_k,
        R: calc_color_for_r,
        H: calc_color_for_r,
        AVG: calc_color_for_r
    };

    const getCells = (oppTeamStats, temp) => {
        const result = [];

        statsToShow.forEach(s => {
            const value = oppTeamStats?.[s];
            if (value == 0) {
                result.push(<td className={`recent-stats red-gradient`}>0x</td>)
                return;
            }
            if (s == 'G') {
                result.push(<th>L{value || temp}</th>)
                return;
            }

            const color = color_rules_dictionary[s](value / lg_avgs[s]);

            const display_value = value ? `${remove_leading_zeros((value / lg_avgs[s]).toFixed(1))}x` : '--';

            result.push(<td className={`text-size-80  recent-stats ${value ? color : ''}`}>{display_value}</td>)
            return;
        })
        return result;
    }
    const lastGameCells = getCells(oppTeamStats.L1?.[breakdown], '1');

    const last3gamesCells = getCells(oppTeamStats.L3?.[breakdown], '3');

    const last7gamesCells = getCells(oppTeamStats.L7?.[breakdown], '7');

    const last10gamesCells = getCells(oppTeamStats.L10?.[breakdown], '10');

    const last14gamesCells = getCells(oppTeamStats.L14?.[breakdown], '14');

    const table_props = { headerCells, lastGameCells, last3gamesCells, last7gamesCells, last10gamesCells, last14gamesCells };
    return table_props;
}