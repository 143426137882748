import { useState } from "react";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";


const PlayerSearchFilter = (props) => {
    const { selectedPosition, setSelectedPosition } = props;
    const { selectedTimeframe, setSelectedTimeframe } = props;
    const { selectedSortCriteria, setSelectedSortCriteria } = props;

    return (
        <Row className='player-search-filter'>
            <Col>
                <Row className="gx-2">
                    <Col className="search-filter-dropdown" xs='4' md='3'><CriteriaDropdown setSelectedItem={setSelectedSortCriteria} selectedItem={selectedSortCriteria}></CriteriaDropdown></Col>
                    <Col className="search-filter-dropdown" xs='4' md='3'><PositionDropdown setSelectedItem={setSelectedPosition} selectedItem={selectedPosition}></PositionDropdown></Col>
                    <Col className="search-filter-dropdown" xs='4' md='3'><TimeframeDropdown setSelectedItem={setSelectedTimeframe} selectedItem={selectedTimeframe} ></TimeframeDropdown></Col>

                </Row>
                <Row className="gx-2 apply-filter-button-row">
                    <Col className="min-width-100" xs='4' md='3'></Col>
                    <Col className="" xs='4' md='3'></Col>
                    <Col className="text-align-center" xs='4' md='3'>
                        <Button className='' size="sm" color="primary" onClick={props.onSubmit}>Apply Filter</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default PlayerSearchFilter;



const PositionDropdown = (props) => {
    const { selectedItem, setSelectedItem } = props;

    const [isOpen, setIsOpen] = useState();
    const toggle = () => setIsOpen((prevState) => !prevState);

    return (
        <div>
            <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
                <DropdownToggle caret className='filter-dropdown'>{selectedItem.display}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'All Batters', value: 'B' }) }}>All Batters</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'C', value: 'C' }) }}>C</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: '1B', value: '1B' }) }}>1B</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: '2B', value: '2B' }) }}>2B</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: '3B', value: '3B' }) }}>3B</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'SS', value: 'SS' }) }}>SS</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'OF', value: 'OF' }) }}>OF</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'Util', value: 'Util' }) }}>Util</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

const TimeframeDropdown = (props) => {
    const { selectedItem, setSelectedItem } = props;
    const [isOpen, setIsOpen] = useState();

    const toggle = () => setIsOpen((prevState) => !prevState);

    return (
        <div>
            <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
                <DropdownToggle caret className='filter-dropdown'>{selectedItem.display}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'Season', value: 'season' }) }}>Season</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'Last Week', value: 'lastweek' }) }}>Last 7</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'Last 14', value: 'last14' }) }}>Last 14</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'Last 30', value: 'lastmonth' }) }}>Last 30</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

const CriteriaDropdown = (props) => {
    const { selectedItem, setSelectedItem } = props;
    const [isOpen, setIsOpen] = useState();

    const toggle = () => setIsOpen((prevState) => !prevState);

    return (
        <div>
            <Dropdown isOpen={isOpen} toggle={toggle} size="sm">
                <DropdownToggle caret className='filter-dropdown'>{selectedItem.display}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'Rank', value: 'AR' }) }}>Rank</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'HR', value: '12' }) }}>HR</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'SB', value: '16' }) }}>SB</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'RBI', value: `13` }) }}>RBI</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'R', value: `7` }) }}>R</DropdownItem>
                    <DropdownItem onClick={() => { setSelectedItem({ display: 'H/AB', value: `60` }) }}>H/AB</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}


