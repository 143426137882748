import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// src/index.js or src/App.js
import 'firebaseui/dist/firebaseui.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import './App.css';
import "typeface-roboto";
import TeamStats from './components/containers/team-stats'
import reportWebVitals from './reportWebVitals';
import AddDropScheduler from './components/add-drop-scheduler/schedule-add-drop-container';
import GameWeatherContainer from './components/game-weather/game-weather-container'
import WatchlistContainer from './components/watchlist/watchlist-container';
import Layout from './components/layout';
import { AuthProvider } from './context/auth-context';
import LeagueMatchupsContainer from './components/league-matchups/league-matchups-container';
import GameSweatContainer from './components/game-sweater/game-sweat-container';
import RosterMatchups from './components/roster-matchups/roster-matchups-container'
import MlbTransactions from './components/mlb-transactions/mlb-transactions-container';
import HitterFinder from './components/hitter-finder/hitter-finder-container';
import StrategyBuilder from './components/strategy-builder/strategy-builder-container';
import LandingPage from './components/landing-page/landing-page-container';
import ProjectedStarters from './components/projectedStarters';
import { LeagueSelectorProvider } from './context/league-selector-context';
import RosterResearcher from './components/roster-researcher/roster-researcher-container';
import LeagueResearcher from './components/league-research/league-research-container';
import { WeeklySummaries } from './components/weekly-summaries/weekly-summary-container';
import RecordBookContainer from './components/record-book/RecordBookContainer';
import AccountManagementContainer from './components/account-management/account-management-container';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from './stripe/stripe';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);



const meta = document.createElement('meta');
meta.httpEquiv = 'Content-Security-Policy';
meta.content = `default-src 'self' https://fantasy-forager.firebaseapp.com https://*.fantasyforager.com;
script-src 'self' 'unsafe-inline' 'unsafe-eval' https://apis.google.com https://www.googletagmanager.com https://*.googleapis.com https://*.gstatic.com https://accounts.google.com https://js.stripe.com https://www.youtube.com https://www.youtube.com/iframe_api;
style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
font-src 'self' https://fonts.gstatic.com;
connect-src 'self' http://localhost:5000 https://projected-starters-api.onrender.com https://*.firebaseio.com https://*.googleapis.com https://*.gstatic.com https://checkout.stripe.com;
img-src 'self' data: *;
frame-src 'self' https://fantasyforager.com https://*.fantasyforager.com https://js.stripe.com https://www.youtube.com https://www.youtube-nocookie.com;
`;
document.head.appendChild(meta);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <LeagueSelectorProvider>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LandingPage></LandingPage>} />
            <Route path="pitcher-finder" element={<ProjectedStarters></ProjectedStarters>} />
            <Route path="team-stats" element={<TeamStats />} />
            <Route path="add-drop-scheduler" element={<AddDropScheduler></AddDropScheduler>} />
            <Route path="game-weather" element={<GameWeatherContainer></GameWeatherContainer>} />
            <Route path="watchlist" element={<WatchlistContainer></WatchlistContainer>} />
            <Route path="matchup" element={<LeagueMatchupsContainer></LeagueMatchupsContainer>} />
            <Route path="game-sweater" element={<GameSweatContainer></GameSweatContainer>} />
            <Route path="roster-matchups" element={<RosterMatchups></RosterMatchups>} />
            <Route path="mlb-transactions" element={<MlbTransactions></MlbTransactions>} />
            <Route path="hitter-finder" element={<HitterFinder></HitterFinder>} />
            <Route path="roster-researcher" element={<RosterResearcher></RosterResearcher>} />
            <Route path="strategy-builder" element={<StrategyBuilder></StrategyBuilder>} />
            <Route path="league-researcher" element={<LeagueResearcher></LeagueResearcher>} />
            <Route path="weekly-summaries" element={<WeeklySummaries></WeeklySummaries>} />
            <Route path="record-book" element={<RecordBookContainer></RecordBookContainer>} />
            <Route path="account-management" element={
              <Elements stripe={stripePromise}>
                <AccountManagementContainer></AccountManagementContainer>
              </Elements>
            } />
            <Route path="*" element={<LandingPage></LandingPage>} />
          </Route>
        </Routes>

      </BrowserRouter>
    </LeagueSelectorProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
