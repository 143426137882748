import { useState } from "react";
import { Row, Table } from "reactstrap";
import { remove_leading_zeros } from "../../display-helpers/remove-leading-zeros";
import { get_display_value, get_display_value_for_avgs } from "../../display-helpers/stat_value_display";

export const RosteredPlayersTable = (props) => {
    const [viewSelected, setViewSelected] = useState('totals');
    const { stat_categories, selectedStat, setSelectedStat, roster, subset_selected_key, hitter_stats_by_timeframe, pitcher_stats_by_timeframe } = props;

    const toggleView = () => {
        if (viewSelected === 'totals') {
            setViewSelected('per_game')
        } else {
            setViewSelected('totals')
        }
    }

    const handleStatSelected = (stat) => {
        const isHitterStat = stat_categories.stats.stat.find(x => x.stat_id === stat.stat_id).group === 'batting';
        if (!isHitterStat) {
            setViewSelected('totals')
        }

        if (stat.is_only_display_stat != '1') {
            setSelectedStat(stat);
        }
    }

    if ((stat_categories?.length == 0) || !selectedStat) {
        return <div></div>;
    }

    const hitters = roster?.hitters;
    const pitchers = roster?.pitchers;

    if ((hitters?.length == 0) || (pitchers?.length == 0)) {
        return <div></div>
    }

    const isHitterStat = stat_categories.stats.stat.find(x => x.stat_id === selectedStat.stat_id).group === 'batting';
    if (selectedStat) {
        if (isHitterStat && hitter_stats_by_timeframe?.length > 0) {
            sortPlayersForTimeframeAndViewSelected(hitter_stats_by_timeframe, subset_selected_key, viewSelected, hitters, selectedStat);
        } else if (pitcher_stats_by_timeframe?.length > 0) {
            sortPlayersForTimeframeAndViewSelected(pitcher_stats_by_timeframe, subset_selected_key, viewSelected, pitchers, selectedStat);
        }

        // sortPlayersByStat('pitchers', pitchers, selectedStat.stat_id, viewSelected)
    }



const players = isHitterStat ? hitters : pitchers;

const filter = isHitterStat ? 'batting' : 'pitching';
const filtered_stat_categories = stat_categories.stats.stat.filter(x => x.group === filter);

const headerValues = filtered_stat_categories.map(s => {
    return { display: s.display_name, abbr: s.abbr, active: s.stat_id == selectedStat.stat_id, stat_id: s.stat_id, onClick: () => handleStatSelected(s) };
});


    headerValues.unshift({ display: 'G', abbr: 'G', onClick: () => null })


const headers = headerValues.map((h, i) => <th key={i} className={h.active ? 'active-stat' : ''} onClick={h.onClick}>{h.display}</th>);
headers.unshift(<th key={-1}></th>);
headers.push(<th key={headerValues.length + 1}></th>)

const tableRows = players.map((p, i) => {


    const players_stats = isHitterStat ? hitter_stats_by_timeframe : pitcher_stats_by_timeframe;

    const this_players_stats_by_timeframe = players_stats.find(hs => hs.player.yahoo_id == p.player_id);
    const this_players_stats_for_subset = this_players_stats_by_timeframe?.cumulative_stats.find(x => x.interval == subset_selected_key);

    const tableData = (viewSelected === 'totals' || !isHitterStat) ? this_players_stats_for_subset?.stats.totals : this_players_stats_for_subset?.stats.per_game;
    if (!tableData) {
        return null;
    }

    const statCells = headerValues.map((hv, j) => {
        let stat_value_display;
        if (viewSelected == 'totals' || !isHitterStat) {
            stat_value_display = get_display_value(hv, tableData[hv.abbr])
        } else {
            stat_value_display = get_display_value_for_avgs(hv, tableData[hv.abbr]);
        }

        return <td key={j}>{stat_value_display}</td>;
    })

    return <tr key={i}><td>{p.name.full}</td>{statCells}</tr>;


});

const subset_prefix = subset_selected_key == 'season' ? 'Season' : subset_selected_key === 'L0' ? 'This Week' : subset_selected_key;
const title = viewSelected === 'totals' || !isHitterStat ? `${subset_prefix} Totals` : `${subset_prefix} Avgs Per Game `

return (
    <>
    <Row className="text-align-center chart-title-one-off"><h6>{title}</h6></Row>
        
        <Table size='sm' className="text-size-70 margin-5 text-align-center">
            <thead>
                <tr className="">
                    {headers}
                </tr>
            </thead>
            <tbody onClick={toggleView}>
                {tableRows}
            </tbody>
        </Table>
    </>
);
};

function sortPlayersForTimeframeAndViewSelected(player_stats_by_timeframe, subset_selected_key, viewSelected, players, selectedStat) {
    const player_stats_for_view_and_subset = player_stats_by_timeframe.map(player => {
        const cum_stats_for_subset = player.cumulative_stats.find(x => x.interval === subset_selected_key).stats;
        const stats_for_subset_and_view = cum_stats_for_subset[viewSelected];
        return { player: player.player, stats: stats_for_subset_and_view };
    });

    sortPlayersByStat(players, selectedStat.stat_id, viewSelected, player_stats_for_view_and_subset);
}

function sortPlayersByStat(players, statId, view, stats_for_sorting) {

    const getStatValue = (player, statId) => {
        const stat = player.player_stats.stats.stat.find(s => s.stat_id == statId);
        if (!stat) {
            return null;
        }
        const player_of_interest = stats_for_sorting.find(x => x.player.yahoo_id == player.player_id);
        const stat_of_interest = player_of_interest?.stats[stat.display_name];
        return stat_of_interest ? parseFloat(stat_of_interest) : null;

    };

    const getSortOrder = (player, statId) => {
        const stat = player.player_stats.stats.stat.find(s => s.stat_id == statId);
        return stat ? stat.sort_order : null;
    };


    const sort_order = getSortOrder(players[0], statId);

    // Sort the players array by the specified stat_id
    players.sort((a, b) => {
        const statA = getStatValue(a, statId);
        const statB = getStatValue(b, statId);

        if (statA === null && statB === null) return 0;
        if (statA === null) return 1;
        if (statB === null) return -1;

        if (sort_order == 1) {
            return statB - statA;
        }
        return statA - statB;

    });

    return players;
}