import { useState, useEffect } from 'react';
import SubscriptionSelector from './SubscriptionPlans';
import CheckoutForm from '../../stripe/CheckoutForm';
import { cancelSubscription, getSubscriptionStatus, recordUserSubscription } from '../../stripe/stripe-service';
import { useAuthContext } from '../../context/auth-context';
import { PRODUCT_PRICES } from '../../stripe/stripe';
import { Col, Row } from 'reactstrap';
import { fromUnixToMMDDYYYY } from '../../dates/date-helper';
import { plans } from './subscription-plans';

const AccountManagementContainer = () => {
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(plans.find(plan => plan.id === PRODUCT_PRICES.SEASON));
    const { userToken, refreshUserToken } = useAuthContext();

    useEffect(() => {
        fetchSubscriptionStatus();
    }, []);

    const fetchSubscriptionStatus = async () => {
        try {
            const response = await getSubscriptionStatus(userToken, refreshUserToken);
            const data = response.data;
            console.log(data)
            setCurrentSubscription(data);   
            setLoading(false);
        } catch (error) {
            console.error('Error fetching subscription:', error);
            setLoading(false);
        }
    };
   

    const handleCancelSubscription = async () => {
        console.log('Cancelling subscription');
        const response = await cancelSubscription(userToken, refreshUserToken);

        if (response.success) {
            await fetchSubscriptionStatus();
        }
    };

    const handleSelectPlan = (planId) => {
        setSelectedPlan(planId);
    };

    const handlePaymentSuccess = async (data) => {

        console.log('Payment successful. Adding record to database.');
        await recordUserSubscription(data.subscriptionId);


        // Refresh subscription status
        const response = await getSubscriptionStatus(userToken, refreshUserToken);
            const subStatus = response.data;
            setCurrentSubscription(subStatus);   
            setLoading(false);
    };

    const handlePaymentError = (error) => {
        alert(error); // Replace with proper error handling
        setSelectedPlan(null);
    };


    return (
        <div className="account-management">
            {loading ? (
                <div>Loading...</div>
            ) : (
                currentSubscription?.status === 'active' ? (
                    <>
                    <ManageExistingSubscription 
                        currentSubscription={currentSubscription}
                        handleCancelSubscription={handleCancelSubscription}
                    />
                    {currentSubscription.cancelAtPeriodEnd &&
                    <SelectNewSubscription 
                        selectedPlan={selectedPlan || {id: PRODUCT_PRICES.PRO}}
                        handleSelectPlan={handleSelectPlan}
                        handlePaymentSuccess={handlePaymentSuccess}
                            handlePaymentError={handlePaymentError}
                        />
                    }
                    </>
                ) : (
                    <SelectNewSubscription 
                        selectedPlan={selectedPlan || {id: PRODUCT_PRICES.PRO}}
                        handleSelectPlan={handleSelectPlan}
                        handlePaymentSuccess={handlePaymentSuccess}
                        handlePaymentError={handlePaymentError}
                />
            ))}  
        </div>
    );
};

const ManageExistingSubscription = (props) => {
    const { currentSubscription, handleCancelSubscription } = props;

    const billingDateLabel = currentSubscription.cancelAtPeriodEnd ? 'Subscription Ends:' : 'Next Billing Date:'

    const plan = plans.find(plan => plan.id === currentSubscription.planId);
    return (
        <>
        <Row>
            <Col>
                <h2>Current Subscription:</h2>
            </Col>
        </Row>
            <Row>
                <Col>Plan:</Col>
                <Col>{plan.name}</Col>
            </Row>
            <Row>
                <Col>Price:</Col>
                <Col>{plan.price}</Col>
            </Row>
            <Row>
                <Col>{billingDateLabel}</Col>
                <Col>{fromUnixToMMDDYYYY(currentSubscription.currentPeriodEnd)}</Col>
            </Row>
            <Row>
                <Col>Status:</Col>
                <Col>{currentSubscription.cancelAtPeriodEnd ? 'Cancelled' : currentSubscription.status}</Col>
            </Row>
            <Row>
                <Col>
                {!currentSubscription.cancelAtPeriodEnd && <button onClick={handleCancelSubscription}>Cancel Subscription</button>}
                </Col>
            </Row>
            <hr />
        </>
    );
};


const SelectNewSubscription = (props) => {
    const { selectedPlan, handleSelectPlan, handlePaymentSuccess, handlePaymentError } = props;
    return (
        <>

        <SubscriptionSelector 
            selectedPlan={selectedPlan}
            onSelectPlan={handleSelectPlan}
        />
        <hr />
        {selectedPlan && (
            <>
            <Row className="checkout-section">
                <Col xs={7} className="margin-bottom-10">
                    <h2>Payment Details:</h2>
                </Col>
                <Col>
                    {/* <img className="powered-by-stripe" src={`${process.env.PUBLIC_URL}/powered-by-stripe.svg`}></img> */}
                </Col>
               
            </Row>
            <Row className="margin-bottom-10">
                <Col>
                 <CheckoutForm 
                    planId={selectedPlan.id}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                />
                </Col>
            </Row>
            <Row className="text-align-center align-items-center">
                <Col>
                    <img className="powered-by-stripe" src={`${process.env.PUBLIC_URL}/powered-by-stripe.svg`}></img>
                </Col>
            </Row>
            </>
        )}
        </>
    );
};




export default AccountManagementContainer;
