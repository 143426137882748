import { Row, Col } from 'reactstrap'
import FirebaseAuthUI from '../firebase/FirebaseAuthUi';
import YouTubeClip from '../youtube/YouTubeClip';

const RegisterContainer = (props) => {
    return (
        <div
            className='landing-page'
        >
            <Row className="white-text text-align-center">
                <Col sm="2"></Col>
                <Col sm="8">
                    {/* <h1>Big Title Banner With App Name</h1> */}
                </Col>

            </Row>
            {RenderLandingPageContent()}
        </div>
    )
}

export default RegisterContainer;

function RenderLandingPageContent() {
    // const content = RenderOriginalContent();
    const content = RenderNewContent_1();

    return content;
}

function RenderOriginalContent() {
    return <Row className="landing-page-content">
        <Col sm="2"></Col>
        <Col sm="4" className='white-text'>
            <h1>Find YOUR league's best available players with favorable matchups.</h1>
            <p></p>
            <p>Our app integrates with your Fantasy League to provide <strong>real-time, league specific </strong>data</p>
            <h5>Sign up now to immediately double your effectiveness as a manager.</h5>
            <ul className="d-none d-lg-block">
                <li>Add players with favorable matchups before other managers even know they're available</li>
                <li>Plan ahead for add/drops so you don't miss any opportunity for edge over your opponent</li>
                <li>Manage multiple leagues at once</li>
            </ul>
            <div className='text-align-center'>
                <FirebaseAuthUI></FirebaseAuthUI>
            </div>
        </Col>
        <Col className="app-image-container" sm="5">
            <img className="app-sample-img d-none d-lg-block" src={`${process.env.PUBLIC_URL}/combined-laptop-phone.png`}></img>
            {/* <img className="app-phone-img d-block d-sm-none" src={`${process.env.PUBLIC_URL}/my-app-on-phone-1.png`}></img>                   */}
        </Col>
    </Row>;
}

function RenderNewContent() {
    return <>
        <Row className="ff-logo-container justify-center">
            <img className="ff-logo" src={`${process.env.PUBLIC_URL}/ff-logo-v1.jpg`}></img>
        </Row>
        <Row className="sales-pitch text-align-center ">
            <Col className=''>
            <h1 className="text-3xl font-bold">Become Your Fantasy League’s Best Manager</h1>
               
                <p className="text-align-lefttext-base mt-4">
                    Fantasy Forager integrates directly with your league, giving you the edge to:
                </p>
                <div className="benefits-container">
                    <Row>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            🔥 Find top free agents before anyone else knows they're available
                        </div>
                        </Col>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            ⏳ Automate add/drops to process overnight
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            🎯 Improve your success rate on waiver pickups
                        </div>
                        </Col>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            ⚡ Effortlessly manage multiple leagues with ease
                        </div>
                        </Col>
                    </Row>
                </div>
                

                <h5 className="text-lg font-semibold mt-5">
                    Sign up now and instantly double your effectiveness as a fantasy manager.
                </h5>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='text-align-center'>
                <FirebaseAuthUI></FirebaseAuthUI>
            </div>
            </Col>
        </Row>
        <Row className="flex-nowrap horizontal-scroll landing-page-carousel ">
           
            <Col xs="8" className='text-align-center landing-page-carousel-item'>
                <Row>
                    <Col>
                        {/* <h4>Find Pitching Matchups</h4> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <YouTubeClip videoId="kTMHCj5wBlI"></YouTubeClip>
                    </Col>
                </Row>
               
            </Col>
            <Col xs="8" className='text-align-center landing-page-carousel-item'>
                <Row>
                    <Col>
                        <YouTubeClip videoId="QSv3-OCUsJ4"></YouTubeClip>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Identify Pitcher Matchups */}
                    </Col>
                </Row>
            </Col>
            {/* <Col sm="8" className='white-text text-align-center'>
                <Row>
                    <Col>
                        <img className="app-img-carousel" src={`${process.env.PUBLIC_URL}/landing-make-informed-decisions.png`}></img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Make Informed Decisions
                    </Col>
                </Row>
            </Col> */}
            
        </Row>
        <Row></Row>
        <Row className="text-align-center">
            <p>
                Join our <a className="discord-link" href='https://discord.gg/aAmmCAwyJw' target='_'>Discord Server</a> if you have any questions.
            </p>
        </Row>
    
    </>
}

function RenderNewContent_1() {
    return <>
        <Row className="ff-logo-container justify-center">
            <img className="ff-logo" src={`${process.env.PUBLIC_URL}/ff-logo-v1.jpg`}></img>
        </Row>
        <Row className="sales-pitch text-align-center ">
            <Col className=''>
                <h1 className="text-3xl font-bold">Become Your Fantasy League’s Best Manager</h1>
                
                <p className="text-align-center text-base mt-4">
                    Do you want to dominate your fantasy league?
                </p>
                <div className="benefits-container">
                    <Row className="text-align-">
                    <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            ⏳ Spend less time on research
                        </div>
                        </Col>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            🧠 Make better decisions
                        </div>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            🎯 Improve free agent performance
                        </div>
                        </Col>
                        <Col xs="12" md="6" className="benefit-item">
                        <div className="text-align-left text-size-70 mb-3">
                            ⚡ Effortlessly manage multiple leagues
                        </div>
                        </Col>
                    </Row>
                </div>
                

                <h5 className="text-lg font-semibold mt-3">
                    Sign up now to 5x your effectiveness as a fantasy manager.
                </h5>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='text-align-center'>
                <FirebaseAuthUI></FirebaseAuthUI>
            </div>
            </Col>
        </Row>
        <Row className="flex-nowrap horizontal-scroll landing-page-carousel ">
           
            <Col xs="8" className='text-align-center landing-page-carousel-item'>
                <Row>
                    <Col>
                        {/* <h4>Find Pitching Matchups</h4> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <YouTubeClip videoId="kTMHCj5wBlI"></YouTubeClip>
                    </Col>
                </Row>
               
            </Col>
            <Col xs="8" className='text-align-center landing-page-carousel-item'>
                <Row>
                    <Col>
                        <YouTubeClip videoId="QSv3-OCUsJ4"></YouTubeClip>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* Identify Pitcher Matchups */}
                    </Col>
                </Row>
            </Col>
            {/* <Col sm="8" className='white-text text-align-center'>
                <Row>
                    <Col>
                        <img className="app-img-carousel" src={`${process.env.PUBLIC_URL}/landing-make-informed-decisions.png`}></img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Make Informed Decisions
                    </Col>
                </Row>
            </Col> */}
            
        </Row>
        <Row></Row>
        <Row className="text-align-center">
            <p>
                Join our <a className="discord-link" href='https://discord.gg/aAmmCAwyJw' target='_'>Discord Server</a> if you have any questions.
            </p>
        </Row>
    
    </>
}