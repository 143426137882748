import { Col, Row } from "reactstrap";
import PitcherSeasons from "../watchlist/season-stats";
import { useState } from "react";
import BattingLineup from "../watchlist/batting-lineup";

export const PitcherStatsView = (props) => {
    const [selectedView, setSelectedView] = useState('basic');

    return (
        <div>
            <ViewSelector view={selectedView} onClick={setSelectedView}></ViewSelector>
            {selectedView === 'basic' && <PitcherSeasons playerId={props.pitcher.player_id}></PitcherSeasons>}
            {selectedView === 'vs-lineup' && <BattingLineup pitcher={props.pitcher} lineup={props.opposingTeam.battingOrder}></BattingLineup>}
        </div>
    );
};


const ViewSelector = (props) => {
    const { view, onClick } = props;

    return (
        <div>
            <Row className=''>
                <Col
                    className={`bottom-border view-selector ${view == 'basic' ? 'active' : ''}`}
                    onClick={() => onClick('basic')}
                >All

                </Col>
                <Col
                    className={`bottom-border view-selector ${view == 'vs-lineup' ? 'active' : ''}`}
                    // onClick={() => onClick('vs-lineup')}
                >Vs Lineup (soon)

                </Col>
                {/* <Col
                    className={`bottom-border view-selector expandable ${view == 'vs-lineup' ? 'active' : ''}`}
                    onClick={() => onClick('vs-lineup')}
                >Vs Lineup (beta)
                </Col> */}
            </Row>
    </div>
    );

}

