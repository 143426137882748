import { remove_leading_zeros } from '../../display-helpers/remove-leading-zeros';
import { useState } from 'react';
import { buildRecentStats } from '../recent-stats/recent_stats_table_builder';
import { RecentGamesTable } from '../recent-stats/RecentGamesTable';


export const RecentGameInfo = (props) => {
    const [activeView, setActiveView] = useState('totals');

    const toggleView = () => {
        if (activeView === 'vs-league-avg') {
            setActiveView('totals')
        } else if (activeView === 'totals') {
            setActiveView('per-game')
        } else {
            setActiveView('vs-league-avg')
        }
    }

    const stats = props.hitter.stats;

    if (!stats) {
        return <div>No Stats</div>;
    }

    let stats_to_show = ["G", "H/AB", "R", "HR", "RBI", "SB", "AVG"]

    const totals_table_props = buildRecentStats(stats, stats_to_show, 'totals');

    stats_to_show = ["G", "H", "R", "HR", "RBI", "SB", "AVG"]
    const per_game_table_props = buildRecentStats(stats, stats_to_show, 'per_game');

    const per_plate_appearance_table_props = buildRecentStatsVsLeagueAverage(stats, stats_to_show, 'per_plate_appearance', props.leagueWideStats.hitter.all.per_plate_appearance[0]);
    const per_game_table_props_vs_avg = buildRecentStatsVsLeagueAverage(stats, stats_to_show, 'per_game', props.leagueWideStats.hitter.all.per_game[0]);

    const title = activeView == 'totals' ? 'Totals' : activeView == 'per-game' ? 'Per Game' : 'vs League Avg (per G)'
    return (
        <div onClick={toggleView}>
            {activeView == 'totals' && <RecentGamesTable title={title} {...totals_table_props}></RecentGamesTable>}
            {activeView == 'per-game' && <RecentGamesTable title={title} {...per_game_table_props}></RecentGamesTable>}
            {activeView == 'vs-league-avg' && <RecentGamesTable title={title} {...per_game_table_props_vs_avg}></RecentGamesTable>}
        </div>

    );
};

function buildRecentStatsVsLeagueAverage(stats, stats_to_show, breakdown, league_averages) {
    const headerCells = stats_to_show.map(x => <th>{x}</th>);
    
    const league_avgs_per_pa = league_averages;


    const getCells = (stats, temp) => {
        const result = [];
        const buffer = .06;
        stats_to_show.forEach(s => {
            const value = stats?.[s];
            if (s == 'G') {
                result.push(<th>L{value || temp}</th>)
                return;
            }
            if (value == 0) {
                result.push(<td className={`recent-stats red-gradient`}>0x</td>)
                return;
            }
           
            const color = (value / league_avgs_per_pa[s]) - buffer > 1 ? 'green-gradient' : 
                (value / league_avgs_per_pa[s]) + buffer < 1 ? 'red-gradient' :
                '';

            const display_value = value ? `${remove_leading_zeros((value / league_avgs_per_pa[s]).toFixed(1))}x` : '--';

            result.push(<td className={`recent-stats ${ value ? color : ''}`}>{display_value}</td>)
            return;
        })
        return result;
    }
    const last3gamesCells = getCells(stats.L3?.[breakdown], '3');

    const last7gamesCells = getCells(stats.L7?.[breakdown], '7');

    const last10gamesCells = getCells(stats.L10?.[breakdown], '10');

    const last14gamesCells = getCells(stats.L14?.[breakdown], '14');

    const table_props = { headerCells, last3gamesCells, last7gamesCells, last10gamesCells, last14gamesCells };
    return table_props;
}