import { useEffect, useState } from 'react';
import { useAuthContext } from '../../context/auth-context';
import { handleUnauthorized } from '../errors/handle-unauthorized';
import { getFreeAgentHitters, searchHittersByName } from '../../api/hitter-finder';
import { Button, Col, Row } from 'reactstrap';
import PlayerSearchFilter from '../player-search-filter/player-search-filter';
import LoadingIndicator from '../loading-indicator';
import { PlayerNameSearchBar } from './PlayerNameSearchBar';
import { UserLeagueDropdown } from '../user-leagues/user-league-dropdown';
import { get_user_leagues_async } from '../user-leagues/get-user-leagues';
import { NoLeagueConnectedInstructions } from '../user-leagues/NoLeagueConnectedInstructions';
import { useLeagueSelector } from '../../context/league-selector-context';
import { HitterInfoContainer } from './HitterInfoContainer';
import { getUserCurrentAndFutureRoster } from '../../api/roster';
import { get_league_settings } from '../../api/yahoo-integration-info';

const HitterFinder = (props) => {
    const { user, userToken, refreshUserToken, logout } = useAuthContext();
    const [isLoading, setIsLoading] = useState();
    const [freeAgentHitters, setFreeAgentHitters] = useState();
    const [pageOffset, setPageOffset] = useState(0);
    const [userLeagues, setUserLeagues] = useState([]);
    const [yahooLeagueSettings, setYahooLeagueSettings] = useState();
    const { selectedLeague, setSelectedLeague } = useLeagueSelector();

    // search filter info
    const [playerSearchInput, setPlayerSearchInput] = useState('');
    const [searchType, setSearchType] = useState('search-filter');

    const [selectedPosition, setSelectedPosition] = useState({ display: "All Batters", value: 'B' });
    const [selectedTimeframe, setSelectedTimeframe] = useState({ display: "Last Week", value: 'lastweek' });
    const [selectedSortCriteria, setSelectedSortCriteria] = useState({ display: 'Rank', value: 'AR' });

    useEffect(() => {
        get_user_leagues_async(userToken, setUserLeagues, logout, refreshUserToken)

        if (selectedLeague?.league_id) {

            const getFreeAgentHitterMatchups = async ( userToken, refreshUserToken, leagueId) => {

                setIsLoading(true)
                const defaultPosition = 'B';
                const defaultTimeframe = 'lastweek';
                const defaultSortCriteria = 'AR';
                const result = await getFreeAgentHitters( userToken, refreshUserToken, leagueId, defaultPosition, defaultTimeframe, defaultSortCriteria, 0);
                if (result.success) {
                    setFreeAgentHitters(result.data);
                    setIsLoading(false);

                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error);
                }
            }

            getFreeAgentHitterMatchups( userToken, refreshUserToken, selectedLeague.league_id);

            const getLeagueSettingsAsync = async (userToken, refreshUserToken, leagueId) => {
                const result = await get_league_settings(userToken, refreshUserToken, leagueId);
                if (result.success) {
                    setYahooLeagueSettings(result.data);
                } else {
                    handleUnauthorized(result.error, logout);
                    console.log(result.error)
                }
            }
            if (userToken && selectedLeague) {
                getLeagueSettingsAsync(userToken, refreshUserToken, selectedLeague.league_id)
            }
        }

    }, [selectedLeague])

    const renderLoadingIndicator = () => {
        return <LoadingIndicator></LoadingIndicator>
    }


    const submitSearchFilter = async (pageOffset) => {
        setIsLoading(true);
        setPlayerSearchInput('');
        setSearchType('search-filter')
        const searchResults = await getFreeAgentHitters(userToken, refreshUserToken, selectedLeague.league_id, selectedPosition.value, selectedTimeframe.value, selectedSortCriteria.value, pageOffset);
        if (searchResults.success) {
            setFreeAgentHitters(searchResults.data);
        } else {
            handleUnauthorized(searchResults.error, logout);
            console.log(searchResults.error);
        }
        setIsLoading(false);

        setTimeout(() => {
            const targetElement = document.querySelector('.hitter-finder-hitter-info-row');
            if (targetElement) {
                if (targetElement.tabIndex > 0) {
                    targetElement.scrollIntoView({ block: 'center', inline: 'center' });
                }
            }
        }, 10)
    }

    const handlePlayerNameSearchSubmit = async (searchTerm) => {
        setIsLoading(true);
        setSearchType('player-name')
        const tempOffset = 0;
        const searchResults = await searchHittersByName(userToken, refreshUserToken, selectedLeague.league_id, searchTerm, tempOffset);
        if (searchResults.success) {
            setFreeAgentHitters(searchResults.data);
        } else {
            handleUnauthorized(searchResults.error, logout);
            console.log(searchResults.error);
        }
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const goToNextPage = async () => {
        const newOffset = 1 + pageOffset;
        setPageOffset(newOffset);
        await submitSearchFilter(newOffset);
    }

    const goToPrevPage = async () => {
        const newOffset = pageOffset - 1;
        setPageOffset(newOffset);
        await submitSearchFilter(newOffset);
    }

    const searchFiltersProps = { playerSearchInput, setPlayerSearchInput, handlePlayerNameSearchSubmit, selectedPosition, setSelectedPosition, selectedTimeframe, setSelectedTimeframe, selectedSortCriteria, setSelectedSortCriteria, pageOffset, submitSearchFilter };
    const pagingRowProps = { goToPrevPage, goToNextPage, pageOffset };
    const hitterInfoContainerProps = { hitters: freeAgentHitters, selectedLeague, yahooLeagueSettings, setYahooLeagueSettings };
    return (
        <div className='fit-better justify-items-center'>
            <div className='hitter-finder-container'>
                <Row className='margin-5'>
                    <UserLeagueDropdown userLeagues={userLeagues} selectedLeague={selectedLeague} onLeagueSelection={setSelectedLeague}></UserLeagueDropdown>
                    {!selectedLeague && ((!(userLeagues?.length > 0) && !isLoading) ?
                        <NoLeagueConnectedInstructions></NoLeagueConnectedInstructions> :
                        <p className='text-align-center'>Select a League to get Started</p>)}
                </Row>
                <Row>
                    <Col xs='0' md='2'></Col>
                    <Col md='8' className=''>        
                        {selectedLeague && renderSearchFilterRows(searchFiltersProps)}
                    </Col>
                </Row>
                <Row className=''>
                    <Col xs='0' md='2'></Col>
                    <Col md='6' className='AAAA'>
                        {selectedLeague && (isLoading ? renderLoadingIndicator() : <HitterInfoContainer {...hitterInfoContainerProps}></HitterInfoContainer>)}
                    </Col>
                </Row>
                {shouldRenderPagingRow(isLoading, freeAgentHitters, searchType) && renderPagingRow(pagingRowProps)}
            </div>
        </div>
    )
}

export default HitterFinder;

const shouldRenderPagingRow = (isLoading, freeAgentHitters, searchType) => {
    return (!isLoading && freeAgentHitters?.length > 0 && searchType != 'player-name')
}

const renderPagingRow = (props) => {
    const { goToPrevPage, goToNextPage, pageOffset } = props;
    return (
        <Row className='justify-center'>
            {pageOffset > 0 && <Col className='text-align-center'><Button onClick={goToPrevPage}>Prev Page</Button></Col>}
            <Col className='text-align-center'><Button onClick={goToNextPage}>Next Page</Button></Col>
        </Row>
    )
}

const renderSearchFilterRows = (props) => {
    const { playerSearchInput, setPlayerSearchInput, handlePlayerNameSearchSubmit, selectedPosition, setSelectedPosition, selectedTimeframe, setSelectedTimeframe, selectedSortCriteria, setSelectedSortCriteria, submitSearchFilter, pageOffset } = props;

    return (
        <Row>
            <Col>
                <Row className='margin-5'>
                    <PlayerNameSearchBar inputValue={playerSearchInput} setInputValue={setPlayerSearchInput} onSubmit={handlePlayerNameSearchSubmit}></PlayerNameSearchBar>
                </Row>
                <Row className='player-search-filter'>
                    <Col>
                        <PlayerSearchFilter
                            selectedPosition={selectedPosition}
                            setSelectedPosition={setSelectedPosition}
                            selectedTimeframe={selectedTimeframe}
                            setSelectedTimeframe={setSelectedTimeframe}
                            selectedSortCriteria={selectedSortCriteria}
                            setSelectedSortCriteria={setSelectedSortCriteria}
                            onSubmit={() => submitSearchFilter(pageOffset)}>

                        </PlayerSearchFilter>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}


