import { Col, Row } from "reactstrap";
import { HitterStatsViewRow } from "./HitterStatsViewRow";

export const HitterLeagueComparisonView = (props) => {

    const { leagueWideStats, hitter } = props;
    const hitterStats = { stats: hitter.stats, splits: hitter.splits };
    const season_splits = hitter.splits?.season;
    const season_stats = hitter?.stats?.season;

    if (!hitterStats || !leagueWideStats?.hitter) {
        return <div></div>
    }
    const seasonHitterStats = season_stats?.per_plate_appearance?.[0];
    const seasonHitterStats_vs_L = season_splits?.per_plate_appearance?.find(x => x.Split == 'vs L');
    const seasonHitterStats_vs_R = season_splits?.per_plate_appearance?.find(x => x.Split == 'vs R');

    console.log(seasonHitterStats_vs_L)
    // console.log(seasonHitterStats_vs_R)

    const seasonLeagueStats = leagueWideStats.hitter.all?.per_plate_appearance?.[0];
    const seasonLeagueStats_vs_L = leagueWideStats.hitter.vs_l?.per_plate_appearance?.[0];
    const seasonLeagueStats_vs_R = leagueWideStats.hitter.vs_r?.per_plate_appearance?.[0];
    // console.log(seasonLeagueStats_vs_L)
    // console.log(seasonLeagueStats_vs_R)

    const shouldRound = true;

    const statsToShow = [
        // { statKey: "PA", statLabel: "PA" },
        { statKey: "HR", statLabel: "HR" },
        { statKey: "RBI", statLabel: "RBI" },
        { statKey: "SB", statLabel: "SB" },
        { statKey: "R", statLabel: "R" },
        { statKey: "AVG", statLabel: "AVG" },
    ]

    const seasonComparison = {};
    const vsLeftyComparison = {};
    const vsRightyComparison = {};

    statsToShow.forEach(stat => {
        const hittersValue = seasonHitterStats?.[stat.statKey] || 0;
        const leaguesValue = seasonLeagueStats?.[stat.statKey] || 0;
        if (leaguesValue == 0) {
            return 0;
        }

        const ratio = hittersValue / leaguesValue;
        seasonComparison[stat.statKey] = ratio;

        const hittersValueVsL = seasonHitterStats_vs_L?.[stat.statKey] || 0;
        const leaguesValueVsL = seasonLeagueStats_vs_L?.[stat.statKey] || 0;
        if (leaguesValueVsL == 0) {
            return 0;
        }

        const ratio1 = hittersValueVsL / leaguesValueVsL;
        vsLeftyComparison[stat.statKey] = ratio1;

        const hittersValueVsR = seasonHitterStats_vs_R?.[stat.statKey] || 0;
        const leaguesValueVsR = seasonLeagueStats_vs_R?.[stat.statKey] || 0;
        if (leaguesValueVsR == 0) {
            return 0;
        }

        const ratio2 = hittersValueVsR / leaguesValueVsR;
        vsRightyComparison[stat.statKey] = ratio2;



    })

    const vsLeftyStatsPerGame = vsLeftyComparison;
    const vsRightyStatsPerGame = vsRightyComparison;
    const seasonStatsPerGame = seasonComparison;

    const dataSet = { vsLeftyStatsPerGame, vsRightyStatsPerGame, seasonStatsPerGame };

    const calc_className_for_color = (value) => {
        const className = value > 1 ? 'green-gradient' : value == 1 ? '' : 'red-gradient'
        return className;
    }

    const rows = statsToShow.map(x => {
        return (
            <HitterStatsViewRow className='bottom-border' calc_className={calc_className_for_color} statKey={x.statKey} statLabel={x.statLabel} shouldRound={shouldRound} isMultiplier toFixed={1} {...dataSet}></HitterStatsViewRow>
        )
    })

    const calc_className = (x) => {
        return '';
    }

    return (
        <div>
            <Row className="bottom-border">
                {/* <Row className="text-align-center"><h5>Player vs League Avg (by PA)</h5></Row> */}
                {/* <Row className="text-align-center"><h6>PA per Stat</h6></Row> */}
                <Col>
                <HitterStatsViewRow className={'bottom-border'} calc_className={calc_className} statKey={'PA'} statLabel={'PA'} shouldRound={false} seasonStatsPerGame={seasonHitterStats} vsRightyStatsPerGame={seasonHitterStats_vs_R} vsLeftyStatsPerGame={seasonHitterStats_vs_L}></HitterStatsViewRow>
                    {rows}
                    
                </Col>
            </Row>
            <Row>
                {/* * stats based on {}  */}
            </Row>
        </div>
    );
};
