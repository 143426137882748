import { Row } from "reactstrap";
import { PitcherSplitsView } from "./PitcherSplitsView";
import LoadingIndicator from "../loading-indicator";
import { SeasonYear } from "../../constants";
export const PitcherSplitsMultiView = (props) => {
    const { activeView, onClick, splits, hitterHand, hitterHomeGame, isLoading } = props;

    const splitsToShowOnLoad = ["vs L", "vs R", "Home", "Away"];

    addHitterHandHomeAwaySplit(hitterHand, hitterHomeGame, splitsToShowOnLoad);


    const title = activeView == 'season' ? 'Season Splits' : 'Career Splits';

    if (isLoading) {
        return (
            <Row>
                <LoadingIndicator className='relative'></LoadingIndicator>
            </Row>)

    }

    return (
        <Row onClick={onClick}>
            {/* <PitcherSplitsViewSelector activeView={activeView} onClick={onClick}></PitcherSplitsViewSelector> */}
            {activeView === 'season' && splits && <PitcherSplitsView className="text-size-70" showTitleRow title={title} splits={splits.filter(x => x.Season == SeasonYear)} splitsToShowOnLoad={splitsToShowOnLoad} bordersToRemove={["vs R", "Away"]} hitterHand={hitterHand} hitterHomeGame={hitterHomeGame}></PitcherSplitsView>}
            {activeView === 'career' && splits && <PitcherSplitsView className="text-size-70" showTitleRow title={title} splits={splits.filter(x => x.Season == 'Total')} splitsToShowOnLoad={splitsToShowOnLoad} bordersToRemove={["vs R", "Away"]} hitterHand={hitterHand} hitterHomeGame={hitterHomeGame}></PitcherSplitsView>}
        </Row>
    );

};

function addHitterHandHomeAwaySplit(hitterHand, hitterHomeGame, splitsToShowOnLoad) {
    if (hitterHand == 'L' && hitterHomeGame) {
        splitsToShowOnLoad.push("Away vs L");
    } else if (hitterHand == 'R' && hitterHomeGame) {
        splitsToShowOnLoad.push("Away vs R");
    } else if (hitterHand == 'L' && !hitterHomeGame) {
        splitsToShowOnLoad.push("Home vs L");
    } else if (hitterHand == 'R' && !hitterHomeGame) {
        splitsToShowOnLoad.push("Home vs R");
    }
}
