import { Col, Row } from 'reactstrap';
import { HitterInfo } from './HitterInfo';
import { useEffect, useState } from 'react';
import { getLeagueWideHitterStats } from '../../api/hitter-stats';
import { useAuthContext } from '../../context/auth-context';
import { handleUnauthorized } from '../errors/handle-unauthorized';
import { getMlbGamesPitcherHandedness } from '../../api/mlb-game-info';
import { getLeagueWidePitcherStats } from '../../api/pitcher-stats';
import {formatDateToMonthSlashDay, getTodayAndFollowingWeek } from '../../dates/date-helper';
import { get_league_settings } from '../../api/yahoo-integration-info';
import { getUserCurrentAndFutureRoster } from '../../api/roster';

export const HitterInfoContainer = (props) => {
    const {user, userToken, refreshUserToken, logout} = useAuthContext();
    const [leagueWideHitterStats, setLeagueWideHitterStats] = useState([]);
    const [leagueWidePitcherStats, setLeagueWidePitcherStats] = useState([]);
    const [mlbGamePitcherHandedness, setMlbGamePitcherHandedness] = useState([]);
    const [rosters, setRosters] = useState([]);

    const { hitters, selectedLeague, yahooLeagueSettings } = props;

    useEffect(() => {

        const getLeagueWideHitterStatsAsync = async (userToken, refreshUserToken) => {
            const result = await getLeagueWideHitterStats(userToken, refreshUserToken);
            if (result.success) {
                setLeagueWideHitterStats(result.data);
            } else {
                handleUnauthorized(result.error, logout);
                console.log(result.error);
            }
        }

        getLeagueWideHitterStatsAsync(userToken, refreshUserToken);

        const getMlbGamesPitcherHandednessAsync = async (userToken, refreshUserToken) => {
            const result = await getMlbGamesPitcherHandedness(userToken, refreshUserToken);
            if (result.success) {
                setMlbGamePitcherHandedness(result.data);
            } else {
                handleUnauthorized(result.error, logout);
                console.log(result.error)
            }
        }

        getMlbGamesPitcherHandednessAsync(userToken, refreshUserToken);

        const getLeagueWidePitcherStatsAsync = async (userToken, refreshUserToken) => {
            const result = await getLeagueWidePitcherStats(userToken, refreshUserToken);
            if (result.success) {
                setLeagueWidePitcherStats(result.data);
            } else {
                handleUnauthorized(result.error, logout);
                console.log(result.error)
            }
        }

        getLeagueWidePitcherStatsAsync(userToken, refreshUserToken);

        

        const getRosterAsync = async (userToken, refreshUserToken, leagueId, teamId) => {
            const resp = await getUserCurrentAndFutureRoster(userToken, refreshUserToken, leagueId, teamId);
            if (resp.success) {
                setRosters(resp.data);
            } else {
                handleUnauthorized(resp.error, logout)
                console.error(resp.error)
            }
        }

        if (userToken && selectedLeague) {
            getRosterAsync(userToken, refreshUserToken, selectedLeague.league_id, selectedLeague.team_id);
        }

       
    }, [selectedLeague])

    const renderNoResults = (rows) => {
        return (
            <Row>
                No Results. Adjust your search criteria.
            </Row>
        )
    }

    const freeAgentRows = hitters?.map((fa, index) => {
        var { gamesVsLefty, gamesVsRighty, gamesVsUnknown, pitcher_arm_order, park_factors, game_time_weather, game_dates } = populateMatchupSummaryData(fa);
        return (
            <div>
                <Row id={index} tabIndex={index + 1} className='hitter-finder-hitter-info-row'>
                    <Col className="hitter-finder-hitter-info">
                        <HitterInfo
                            hitter={fa.hitter}
                            gamesVsLefty={gamesVsLefty}
                            gamesVsRighty={gamesVsRighty}
                            gamesVsUnknown={gamesVsUnknown}
                            oppAggregateStats={fa.opposingPitcherAggregateStats}
                            armOrder={pitcher_arm_order}
                            parkFactors={park_factors}
                            gameTimeWeather={game_time_weather}
                            gameDates={game_dates}
                            leagueWideStats={{ hitter: leagueWideHitterStats, pitcher: leagueWidePitcherStats }}
                            mlbGamePitcherHandedness={mlbGamePitcherHandedness}
                            upcomingGames={fa.games}
                            selectedLeague={selectedLeague}
                            rosters={rosters}
                            leagueSettings={yahooLeagueSettings}
                            />
                    </Col>
                </Row>
                <Row className='hitter-finder-break'>

                </Row>
            </div>
        );
    });

    if (freeAgentRows?.length) {
        return freeAgentRows;
    } else {
        return renderNoResults();
    }

};

function populateMatchupSummaryData(fa) {
    let gamesVsLefty = 0, gamesVsRighty = 0, gamesVsUnknown = 0;
    const game_dates = [];
    const park_factors = [];
    const game_time_weather = [];
    const pitcher_arm_order = [];
    const game_start_weather_index = 2; // ToDo: this is sketchy but it comes with 2 hours before and after game time


    const upcomingDays = getTodayAndFollowingWeek();


    upcomingDays.forEach(day => {
        const game = fa.games.find(g => g.date == day);

        if (!game) {
            // need to push in placeholders to show that there is a day off
            game_dates.push(formatDateToMonthSlashDay(day));
            park_factors.push({});
            game_time_weather.push({});
            pitcher_arm_order.push('X');
            return;
        }
        
        game_dates.push(formatDateToMonthSlashDay(game.date));
        park_factors.push(game.parkFactor);
        game_time_weather.push(game.gameDetails?.weather?.[game_start_weather_index] || {});

        if (game.opposingPitcher) {

            pitcher_arm_order.push(game.opposingPitcher.throwHand);

            if (game.opposingPitcher.throwHand == 'L') {
                gamesVsLefty++;
            } else {
                gamesVsRighty++;
            };
        } else {
            gamesVsUnknown++;
            pitcher_arm_order.push('?');
        }

    });
    return { gamesVsLefty, gamesVsRighty, gamesVsUnknown, pitcher_arm_order, park_factors, game_time_weather, game_dates };
}
