import { Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash} from '@fortawesome/free-solid-svg-icons'

const ConnectedLeagues = (props) => {
    const { userLeagues, deleteLeague } = props;

    const leagueIdMap = { 1: "Yahoo", 2: "Espn" };
    const tableRows = userLeagues.map((league, index) => {

        return (
            <tr key={index}>
                <td>{league.league_id}</td>
                <td>{league.team_name}</td>
                <td>{leagueIdMap[league.league_type_id]}</td>
                <td><FontAwesomeIcon color="red" icon={faTrash} size="1x" onClick={() => deleteLeague(league.league_id)}>Remove</FontAwesomeIcon></td>
            </tr>
        )
    });

    return (
        <div className="connected-leagues-container connect-league-table-container text-size-90">
            <Table className="connected-leagues-table" size="sm" striped bordered hover>
                <thead>
                    <tr>
                        <th>League Id</th>
                        <th>Team</th>
                        <th>Type</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>

        </div>
    )
}

export default ConnectedLeagues;