import Youtube from 'react-youtube';
import React from 'react';

class YouTubeClip extends React.Component {
    render() {
        const { videoId } = this.props; // Get videoId from props

        const options = {
            height: '499.2',
            width: '280.8',
            playerVars: {
                autoplay: 0,
                controls: 1,
                // mute: 1
            },
        };

        return <div className="youtube-clip-container">
            <Youtube iframeClassName='youtube-clip-iframe' videoId={videoId} opts={options} onReady={this._onPlayerReady} id={`youtube-player-${videoId}`} />
        </div>
    }

    _onPlayerReady = (event) => {
        event.target.pauseVideo();
    };
}

export default YouTubeClip;
