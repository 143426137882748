import { Table } from "reactstrap";
import { remove_leading_zeros } from "../../../display-helpers/remove-leading-zeros";

export const SplitsTable = (props) => {
    const { statsToShow, stats, pitcherHand } = props;

    const headers = statsToShow.map(x => <th>{x}</th>);

    const dataSets = [{ key: 'all', display: "All" }, { key: 'vs_r', display: 'vs R' }, { key: 'vs_l', display: 'vs L' }];

    const rows = dataSets.map(set => {
        const setStats = stats[set.key];

        const dataCells = statsToShow.map(key => {
            let displayValue = setStats?.[key]?.toFixed(3) || '--';
            const wholeNumbers = ['PA'];
            if (wholeNumbers.includes(key)) {
                displayValue = setStats?.[key]?.toFixed(0) || '--';
            }
            const twoDigits = ['K%'];
            if (twoDigits.includes(key)) {
                displayValue = setStats?.[key]?.toFixed(2) || '--';
            }
            return <td>{remove_leading_zeros(displayValue)}</td>;
        });

        const highLight = (set.key === 'vs_r' && pitcherHand == 'R') ? ' relevant ' : (set.key === 'vs_l' && pitcherHand === 'L') ? ' relevant ' : '';
        return <tr className={highLight}><th>{set.display}</th>{dataCells}</tr>;

    });



    return (
        <div>
            <Table size='sm' className="text-size-70">
                <thead>
                    <tr><th></th>{headers}</tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    );
};
